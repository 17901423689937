import { useState } from "react";
import './login.css';
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../auth/firebaseConnection";
import { getFirestore, getDocs, collection } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login({ setNotification }) {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const logarUsuario = async (e) => {
    e.preventDefault();
  
    if (email !== '' && senha !== '') {
      try {
        // Realiza o login com o email e senha
        const userCredential = await signInWithEmailAndPassword(auth, email, senha);
        const userId = userCredential.user.uid;
  
        // Consulta o Firestore para verificar o status do usuário
        const db = getFirestore();
  
        // Tenta buscar na coleção userPremium
        const userPremiumRef = collection(db, 'userPremium');
        const userPremiumSnap = await getDocs(userPremiumRef);
  
        let userDoc = null;
        
        // Verifica todos os documentos na coleção userPremium
        userPremiumSnap.forEach((doc) => {
          if (doc.data().idAuth === userId) {
            userDoc = doc;
          }
        });
  
        // Tenta buscar na coleção userMasters caso não encontre em userPremium
        if (!userDoc) {
          const userMasterRef = collection(db, 'userMasters');
          const userMasterSnap = await getDocs(userMasterRef);
  
          userMasterSnap.forEach((doc) => {
            if (doc.data().idAuth === userId) {
              userDoc = doc;
            }
          });
        }
  
        // Verifica se o usuário foi encontrado e o status
        if (userDoc) {
          const userData = userDoc.data();
  
          // Desloga o usuário se inativo
          if (userData.status === 'Inativo') {
            await signOut(auth);
            setNotification({ message: 'Usuário inativo. Entre em contato com o administrador.', type: 'warning' });
            navigate('/', { replace: true });
            return;
          }
  
          // Se o status for Ativo, segue para o sistema
          setNotification({ message: 'Seja Bem-vinda Positiva.', type: 'success' });
          navigate('/home', { replace: true });
        } else {
          // Caso o usuário não seja encontrado em nenhuma coleção
          await signOut(auth);
          setNotification({ message: 'Usuário não encontrado no sistema.', type: 'error' });
        }
  
        setEmail('');
        setSenha('');
      } catch (error) {
        setNotification({ message: 'Erro, verifique seus dados e tente novamente.', type: 'error' });
      }
    } else {
      setNotification({ message: 'Preencha todos os campos.', type: 'warning' });
    }
  };

  return (
    <div>
      <div className="login-container">
        <div className="login-box">
          <h2>Login</h2>
          <form onSubmit={logarUsuario}>
            <div className="input-group">
              <label>Email:</label>
              <input
                type="text"
                placeholder='Seu email'
                value={email}
                onChange={(e) => setEmail(e.target.value)} />
            </div>
            
            <div className="input-group">
              <label>Senha:</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Senha'
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)} />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
            <button className="login-button" type="submit">LOGAR</button>
          </form>
          <p><Link to="/reset-password">Esqueci minha senha</Link></p>
        </div>
      </div>
    </div>
  );
}

export default Login;