import { useState, useEffect } from "react";
import './register.css';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc, getFirestore, getDocs, doc, updateDoc } from "firebase/firestore";
import { auth } from "../../auth/firebaseConnection";
import Header from "../../components/Header"
import Sidebar from "../../components/Sidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

function Register({ setNotification }) {
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefoneFormatado, setTelefoneFormatado] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [status, setStatus] = useState('Ativo');
  const [perfil, setPerfil] = useState('userPremium');
  const [isOpen, setIsOpen] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Estado para edição
  const [editingUser, setEditingUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    async function fetchUsuarios() {
      try {
        const db = getFirestore();
        const usuariosPremiumCollectionRef = collection(db, 'userPremium');
        const usuariosMasterCollectionRef = collection(db, 'userMasters');

        const usuariosPremiumSnapshot = getDocs(usuariosPremiumCollectionRef);
        const usuariosMasterSnapshot = getDocs(usuariosMasterCollectionRef);

        const [premiumDocs, masterDocs] = await Promise.all([
          usuariosPremiumSnapshot,
          usuariosMasterSnapshot,
        ]);

        const usuariosPremiumData = premiumDocs.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id, // Inclui o ID do documento
          collection: 'userPremium', // Define a coleção para os usuários Premium
        }));

        const usuariosMasterData = masterDocs.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id, // Inclui o ID do documento
          collection: 'userMasters', // Define a coleção para os usuários Master
        }));

        const allUsuariosData = [
          ...usuariosPremiumData,
          ...usuariosMasterData,
        ];

        setUsuarios(allUsuariosData);
      } catch (error) {
        setNotification({ message: 'Erro ao buscar usuários.', type: 'error' });
      }
    }

    fetchUsuarios();
  }, [setNotification])

  async function novoUsuario(e) {
    e.preventDefault();

    if (nome !== '' && telefone !== '' && email !== '' && senha !== '') {
      // Adiciona a validação para o formato do número de telefone
      const formatoTelefoneValido = /^\(\d{2}\) \d{5}-\d{4}$/;
      if (!formatoTelefoneValido.test(telefoneFormatado)) {
        setNotification({ message: 'Digite um número de telefone válido.', type: 'warning' });
        return;
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, senha);

        // Mapeia o nome do perfil correspondente
        const perfilNome = {
          userPremium: 'Premium',
          userMasters: 'Master',
        }[perfil];

        // Adiciona o e-mail à coleção correspondente com base no perfil
        const db = getFirestore();
        const userCollectionRef = collection(db, `${perfil}`);
        await addDoc(userCollectionRef, {
          nome,
          telefone,
          status,
          perfil: perfilNome,
          email: userCredential.user.email,
          idAuth: userCredential.user.uid,
        });

        setNotification({ message: 'Usuário cadastrado com sucesso.', type: 'success' });

        setNome('');
        setStatus('');
        setTelefone('');
        setEmail('');
        setSenha('');
        setPerfil('');
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          setNotification({ message: 'Email já cadastrado.', type: 'warning' });
        } else if (error.code === 'auth/invalid-email') {
          setNotification({ message: 'Digite um email válido.', type: 'warning' })
        } else if (error.code === 'auth/weak-password') {
          setNotification({ message: 'A senha deve ter no mínimo 6 caracteres.', type: 'warning' })
        } else {
          setNotification({ message: 'Erro ao cadastrar usuário', type: 'error' })
        }
      }
    } else {
      setNotification({ message: 'Preencha todos os campos.', type: 'warning' });
    }
  }

  // Abrir modal para editar
  const openEditModal = (user) => {
    setEditingUser({
      ...user,
      id: user.id, // Certifique-se de que o ID foi adicionado no fetch
      collection: user.collection, // Certifique-se de que a coleção foi adicionada no fetch
    });
    setShowModal(true);
  };

  // Salvar alterações do usuário
  const saveUserChanges = async () => {
    if (editingUser) {
      try {
        const db = getFirestore();
        const userRef = doc(db, editingUser.collection, editingUser.id);

        await updateDoc(userRef, {
          nome: editingUser.nome,
          telefone: editingUser.telefone,
          status: editingUser.status,
        });

        setNotification({ message: 'Usuário atualizado com sucesso.', type: 'success' });
        setShowModal(false);

        // Atualiza a lista de usuários localmente para refletir as mudanças
        setUsuarios((prevUsuarios) =>
          prevUsuarios.map((usuario) =>
            usuario.id === editingUser.id && usuario.collection === editingUser.collection
              ? { ...usuario, ...editingUser }
              : usuario
          )
        );
      } catch (error) {
        console.error('Erro ao atualizar usuário:', error);
        setNotification({ message: `Erro ao atualizar usuário: ${error.message}`, type: 'error' });
      }
    }
  };

  // formatar telefone 
  const formatarTelefone = (numero) => {
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    const match = numero.match(regex);

    if (match) {
      const telefoneFormatado = `(${match[1]}) ${match[2]}-${match[3]}`;
      setTelefoneFormatado(telefoneFormatado);
    } else {
      setTelefoneFormatado(numero);
    }
  };


  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className="padding" />

        <div className="content">
          <div className="register-box">
            <h2>Cadastro usuário</h2>
            <form onSubmit={novoUsuario}>
              <div className="input-group">
                <label>Nome:</label>
                <input
                  type="text"
                  placeholder='Digite nome'
                  value={nome}
                  onChange={(e) => setNome(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Telefone:</label>
                <input
                  type="text"
                  placeholder='Digite numero de Whatsapp'
                  value={telefoneFormatado}
                  onChange={(e) => {
                    const numeroLimpo = e.target.value.replace(/\D/g, '');
                    setTelefone(numeroLimpo);
                    formatarTelefone(numeroLimpo);
                  }}
                />
              </div>
              <div className="input-group">
                <label>Email:</label>
                <input
                  type="text"
                  placeholder='Digite email válido'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Senha:</label>
                <input
                  type="password"
                  placeholder='Senha'
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)} />
              </div>
              <div className="input-group">
                <label>Perfil:</label>
                <select value={perfil} onChange={(e) => setPerfil(e.target.value)}>
                  <option value="userPremium">Premium</option>
                  <option value="userMasters">Master</option>
                </select>
              </div>
              <button className="btn-cadastrar">CADASTRAR</button>
            </form>
          </div>
        </div>

        <div className="content">
          <div className="usuarios-table">
            <h2>Usuarios Cadastrados</h2>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>Email</th>
                  <th>Perfil</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {usuarios
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((usuario) => (
                    <tr key={usuario.email}>
                      <td>{usuario.nome}</td>
                      <td>{usuario.telefone}</td>
                      <td>{usuario.email}</td>
                      <td className={`perfil-${usuario.perfil.toLowerCase()}`}>{usuario.perfil}</td>
                      <td>
                        <span className={usuario.status === "Ativo" ? "status-ativo" : "status-inativo"}>
                          {usuario.status}
                        </span>
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ cursor: 'pointer', color: '#ca60d6' }}
                          onClick={() => openEditModal(usuario)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {/* Paginação */}
            {Math.ceil(usuarios.length / itemsPerPage) > 1 && (
              <div className='pagination'>
                <button
                  onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Anterior
                </button>
                <span>{`${currentPage} ... ${Math.ceil(usuarios.length / itemsPerPage)}`}</span>
                <button
                  onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(usuarios.length / itemsPerPage)))}
                  disabled={currentPage === Math.ceil(usuarios.length / itemsPerPage)}
                >
                  Próxima
                </button>
              </div>
            )}

          </div>
        </div>
      </div>

      {/* Modal de edição */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">

            <h3>Editar Usuário</h3>
            <div className="input-group">
              <label>Perfil:</label>
              <input
                className="perfil-master"
                type="text"
                value={editingUser.perfil}
                disabled
              />
            </div>
            <div className="input-group">
              <label>Email:</label>
              <input
                type="text"
                value={editingUser.email}
                disabled
              />
            </div>
            <div className="input-group">
              <label>Nome:</label>
              <input
                type="text"
                value={editingUser.nome}
                onChange={(e) =>
                  setEditingUser({ ...editingUser, nome: e.target.value })
                }
              />
            </div>
            <div className="input-group">
              <label>Telefone:</label>
              <input
                type="text"
                value={editingUser.telefone}
                onChange={(e) =>
                  setEditingUser({ ...editingUser, telefone: e.target.value })
                }
              />
            </div>
            <div className="input-group">
              <label>Status:</label>
              <select
                className={editingUser.status === "Ativo" ? "status-ativo" : "status-inativo"}
                value={editingUser.status}
                onChange={(e) =>
                  setEditingUser({ ...editingUser, status: e.target.value })
                }
              >
                <option value="Ativo">Ativo</option>
                <option value="Inativo">Inativo</option>
              </select>
            </div>
            <div className="modal-actions">
              <button className="btn-edit" onClick={saveUserChanges}>Salvar</button>
              <button className="btn-close" onClick={() => setShowModal(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Register;
